$font-family: 'open-sans', sans-serif;

:root {
    --font-size-base: 2rem;
    --font-size-scale-small: 0.2;
    --font-size-scale-medium: 0.3;
    --font-size-scale-large: 0.4;
    --font-size-scale-xlarge: 0.42;

    --icon-size-base: 3.5rem;
    --icon-size-scale-small: 0.8;
    --icon-size-scale-medium: 0.85;
    --icon-size-scale-large: 0.88;
    --icon-size-scale-xlarge: 0.9;

    scroll-behavior: smooth;
}

.hero-text h1 {
    font-size: 6rem;
}

.hero-text h2 {
    font-size: 4rem;
}

.hero-text h3 {
    font-size: 2rem;
}

.hero-link a {
    font-size: 1.5rem;
}

@media (max-width: 1000px) {
    :root {
        font-size: calc(var(--font-size-base) * var(--font-size-scale-xlarge));
        --icon-size: calc(var(--icon-size-base) * var(--icon-size-scale-xlarge));
    }

    .hero-text h1 {
        font-size: 5rem;
    }

    .hero-text h2 {
        font-size: 2.5rem;
    }

    .hero-text h3 {
        font-size: 2rem;
    }

    .hero-link a {
        font-size: 1.5rem;
    }

    .arrow {
        width: 20px;
        height: 20px;
    }

    .project-brief h1 {
        font-size: 2.75rem;
    }
}

@media (max-width: 800px) {
    :root {
        font-size: calc(var(--font-size-base) * var(--font-size-scale-large));
        --icon-size: calc(var(--icon-size-base) * var(--icon-size-scale-large));
    }

    .hero-text h1 {
        font-size: 4rem;
    }

    .hero-text h2 {
        font-size: 2rem;
    }

    .hero-text h3 {
        font-size: 1.5rem;
    }

    .hero-link a {
        font-size: 1.25rem;
    }

    .arrow {
        width: 16px;
        height: 16px;
    }

    .project-brief h1 {
        font-size: 2rem;
    }
}

@media (max-width: 600px) {
    :root {
        font-size: calc(var(--font-size-base) * var(--font-size-scale-medium));
        --icon-size: calc(var(--icon-size-base) * var(--icon-size-scale-medium));
    }

    .hero-text h1 {
        font-size: 3.5rem;
    }

    .hero-text h2 {
        font-size: 2.5rem;
    }

    .hero-text h3 {
        font-size: 2rem;
    }

    .hero-link a {
        font-size: 1.5rem;
    }

    .arrow {
        width: 14px;
        height: 14px;
    }

    .project-brief h1 {
        font-size: 1.75rem;
    }
}

@media (max-width: 400px) {
    :root {
        font-size: calc(var(--font-size-base) * var(--font-size-scale-small));
        --icon-size: calc(var(--icon-size-base) * var(--icon-size-scale-small));
    }

    .hero-text h1 {
        font-size: 3rem;
    }

    .hero-text h2 {
        font-size: 2rem;
    }

    .hero-text h3 {
        font-size: 1.5rem;
    }

    .hero-link a {
        font-size: 1.25rem;
    }

    .arrow {
        width: 12px;
        height: 12px;
    }

    .project-brief h1 {
        font-size: 1.5rem;
    }
}

.hero-link svg {
    width: var(--icon-size);
    height: var(--icon-size);
}

::-webkit-scrollbar {
    width: 0.5rem;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #26363b;
}

ul {
    text-align: left;
}

h1 {
    font-size: 5rem;
    font-family: $font-family;
    font-weight: 400;
}

a {
    text-decoration: none;
}

.about {
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 100%;
    margin-top: 20vh;

    & > h1 {
        position: relative;
        text-align: left;
        font-size: 4rem;
        color: white;
        width: 80vw;
        margin: 0;
    }

    & > hr {
        position: relative;
        width: 80vw;
        margin: 0;
        border: 0.1rem solid white;
    }

    &-details {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        flex-direction: column;
        align-items: left;
        font-family: $font-family;
        font-size: 1.5rem;
        max-width: 95%;
        max-height: 100%;
        
        // &-animation {
        //     animation: slide-in-left 0.5s ease-in-out;
        // }

        h1 {
            margin-top: 2vh;
            margin-bottom: 0;
            text-align: left;
        }

        ul {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &-pics {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2vw;
        margin: 0 1vw;
        width: 60%;
        height: 90%;
        background-color: transparent;

        // &-animation{
        //     animation: slide-in-left 0.4s ease-in-out;
        // }

        img {
            height: auto;
            width: 40%;
            margin: 0.5vh;
            border-radius: 1vw;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }
    }

    &-blurb {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        h1 {
            font-size: 3rem;
        }

    }

    &-socials {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 2vw;
        margin: 0 1vw;
        background-color: transparent;
        font-size: 4rem;
        
        a {
            color: white;
            margin: 0 1vw;
        }
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.connect {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 25vh;
    align-items: center;
    height: fit-content;
    width: 95vw;
    color: #f2f2f2;
    // background-color: rgba(255, 255, 255, 0.425);
    // border: 0.25vw solid rgba(255, 255, 255, 0.685);
    border-radius: 1vw;

    & > h1 {
        width: 100%;
        text-align: left;
        font-size: 4rem;
        margin: 0;
    }

    & > hr {
        width: 100%;
        border: 0.1rem solid white;
        margin-bottom: 4vh;
    }

    &-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-family: $font-family;
        font-size: 1.25rem;
        letter-spacing: 0.05vw;
        overflow: wrap;
        width: 30%;

        &-btn {
            background: white;
            color: black;
            font-family: inherit;
            padding: 0.35em;
            padding-left: 1.2em;
            font-size: 17px;
            font-weight: 500;
            border-radius: 0.9em;
            border: none;
            letter-spacing: 0.05em;
            display: flex;
            align-items: center;
            box-shadow: inset 0 0 1.6em -0.6em white;
            overflow: hidden;
            position: relative;
            height: 2.8em;
            padding-right: 3.3em;
            margin-top: 2vh;
            cursor: pointer;

            .icon {
                background: #26363b;
                margin-left: 1em;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 2.2em;
                width: 2.2em;
                border-radius: 0.7em;
                box-shadow: 0.1em 0.1em 0.6em 0.2em #26363b;
                right: 0.3em;
                transition: all 0.3s;
            }
        }

        &-btn:hover .icon {
            width: calc(100% - 0.6em);
        }

        &-btn .icon svg {
            width: 1.1em;
            transition: transform 0.3s;
            color: white;
        }

        &-btn:hover .icon svg {
            transform: translateX(0.1em);
        }

        &-btn:active .icon {
            transform: scale(0.95);
        }

        & > h3 {
            margin: 0.5vh;
            margin-top: 1vh;
        }
    }
}

.container {
    padding-top: 0;
    display: flex;
    flex-direction: column;
}

.experience {
    display: flex;
    flex-direction: column;
    margin: 5vh 14vw;
    width: 60vw;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.425);
    border: 0.25vw solid rgba(255, 255, 255, 0.685);
    border-radius: 1vw;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    gap: 3vh;

    & > h1 {
        margin-top: 2vh;
        margin-left: 2vw;
        margin-bottom: 0;
    }

    &-details {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;
        margin: 0 2vw;
        margin-bottom: 2vh;
        font-size: clamp(0.5rem, 2vw, 1.5rem);
        
        .work-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 4vh;
            margin-bottom: 0;
            height: 5vh;
            width: 100%;

            h2 {
                font-size: 1.5rem;
                width: 12vw;
                margin-right: 0;
                text-align: left;
                padding: 0;
            }

            hr {
                display: inline-block;
                width: 100%;
                height: 0.01em;
                margin: 0 0.5vw;
            }

            h4 {
                font-size: 1.3rem;
                width: 14.5vw;
                margin-left: 0;
                padding: 0;
                text-align: right;
            }
        }

    }

    .btn-shine {
        position: relative;
        margin-left: 2vw;
        margin-bottom: 2vh;
        height: 6vh;
        width: 6vw;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        background-color: #fff;
        border: 1px solid rgba(22, 76, 167, 0.6);
        border-radius: 10px;
        color: #1d89ff;
        font-weight: 400;
        font-family: inherit;
        z-index: 0;
        overflow: hidden;
        transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
        overflow: hidden;
        position: relative;

        span {
            color: #164ca7;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: 0.7px;
            z-index: 20;
        }
    }

    .btn-shine:hover {
        animation: rotate624 0.7s ease-in-out both;
    }

    .btn-shine:hover span {
        animation: storm1261 0.7s ease-in-out both;
        animation-delay: 0.06s;
    }

    @keyframes rotate624 {
        0% {
          transform: rotate(0deg) translate3d(0, 0, 0);
        }
      
        25% {
          transform: rotate(3deg) translate3d(0, 0, 0);
        }
      
        50% {
          transform: rotate(-3deg) translate3d(0, 0, 0);
        }
      
        75% {
          transform: rotate(1deg) translate3d(0, 0, 0);
        }
      
        100% {
          transform: rotate(0deg) translate3d(0, 0, 0);
        }
    }
      
    @keyframes storm1261 {
        0% {
            transform: translate3d(0, 0, 0) translateZ(0);
        }
        
        25% {
            transform: translate3d(4px, 0, 0) translateZ(0);
        }
        
        50% {
            transform: translate3d(-3px, 0, 0) translateZ(0);
        }
        
        75% {
            transform: translate3d(2px, 0, 0) translateZ(0);
        }
        
        100% {
            transform: translate3d(0, 0, 0) translateZ(0);
        }
    }

    .btn-shine:after {
        background: rgba(11, 247, 255, 0.853);
        content: "";
        height: 155px;
        left: -75px;
        opacity: 0.4;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
        z-index: -10;
    }
      
    .btn-shine:hover:after {
        left: 120%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
}

.footer {
    color: white;
    font-size: 1.5rem;
    font-family: $font-family;
    font-weight: 100;
    letter-spacing: 0.1rem;
}

.hero {
    display: flex;
    gap: 1vw;
    flex-direction: row;
    margin: 0 0;
    align-items: center;
    max-width: 100%;
    min-width: 95vw;
    height: 80vh;
    // background: radial-gradient(circle, rgba(38,54,59,1) 0%, rgba(69,98,111,1) 0%, rgba(38,54,59,1) 35%);

    &-text {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        margin: 0;
        margin-left: 8vw;
        padding: 0;
        width: 100%;
        min-height: 30vh;

        & > h1{
            overflow: hidden;
            white-space: normal;
            margin: 0;
            font-family: $font-family;
            font-weight: 400;
        }
        
        & > h2 {
            font-family: $font-family;
            font-weight: 300;
            margin: 0;
        }

        & > h3 {
            font-family: $font-family;
            font-weight: 100;
            margin: 0;
        }

        p {
            font-size: 25px;
            font-family: $font-family;
        }
    }

    &-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $font-family;
        margin: 0 1vw;
        padding: 0;
        width: 60%;
        gap: 3vh;
        min-height: 30vh;

        .hero-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            margin: 0;
            padding: 1vh;
            width: 20vw;
            height: 5vh;
            color: white;
            font-size: 3rem;
            font-family: $font-family;
            font-weight: 100;
            text-decoration: none;
            transition: all 0.3s ease;
            
            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 1vh;
                width: 10vw;
                height: 5vh;
                border-radius: 1vw;
                color: white;
                font-family: $font-family;
                font-weight: 100;
                text-decoration: none;
            }
    
            a:hover {
                cursor: pointer;
            }

            a:hover ~.second {
                opacity: .66;
                right: -1px;
                transition-delay: 10ms;
            }
            
            a:hover ~.third {
                opacity: .33;
                right: -2px;
                transition-delay: 20ms;
            }
            
            .arrow {
                fill: white;
                pointer-events: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
                width: 2vw;
                height: auto;
                transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            .second, 
            .third {
                opacity: 0;
            }
        }
    }

    &-img {
        display: flex;
        justify-content: center;
        background-size: cover;
        background-position: 50% 50%;
        width: 40%;
        height: 40vh;
        padding: 0;
        margin: 0;
        img {
            clip-path: polygon(0 0,calc(100% - 30px) 0,100% 30px,100% 100%,30px 100%,0 calc(100% - 30px));
        }
    }
}

.home {
    display: flex;
    flex-direction: column;
    padding-bottom: 5vh;
    overflow-x: hidden;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    max-width: 100vw;                
    // background: #26363b;
    color: white;
    font-family: $font-family;
}

@keyframes bounce {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(-10px);
    }
}

.projects {
    display: flex;
    position: relative;
    margin: 0;
    padding: 0;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: black;

    .grid {
        margin: 5vh 0;
        display: grid;
        grid-auto-rows: auto;
        width: 60vw;
        grid-template-columns: repeat(2, 1fr);
        gap: 5.5rem;
        z-index: 200;
        color: var(--color-text-grid);
        height: min-content;

        @media (max-width: 1500px) {
            grid-template-columns: 1fr;
        }

        .project {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.425);
            border: 2.5px solid rgba(255, 255, 255, 0.685);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            border-radius: 20px;
            padding: 0;
            height: min-content;
            overflow: hidden;
            margin: 0;
            
            .card {
                overflow: hidden;
                position: relative;
                display: grid;
                grid-template-columns: repeat(2,1fr);
                grid-template-rows: repeat(2,1fr);
                padding: 0;
                grid-template-areas: 'box-a box-b' 'box-c box-d';
                cursor: pointer;
                margin: 0;
                height: auto;
            
                &__img {
                    display: flex;
                    justify-content: center;
                    background-size: cover;
                    background-position: 50% 50%;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    text-align: center;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    z-index: 1;
                    will-change: filter, transform;
                }
            
                &__box {
                    opacity: 0;
                    z-index: 2;
                    padding: 1rem;
                    display: flex;
                    position: relative;
                    overflow: hidden;
                    flex-direction: column;
                    background-color: transparent;
                    backdrop-filter: blur(10px);
            
                    &--a {
                        grid-area: box-a;
                        border-radius: 20px 0 0 0;
                    }
            
                    &--d {
                        grid-area: box-d;
                        text-align: right;
                    }
            
                    // &--a,
                    // &--d {
                    //     clip-path: polygon(0 0,calc(100% - 30px) 0,100% 30px,100% 100%,30px 100%,0 calc(100% - 30px)); 
                    // }
            
                    // &--b,
                    // &--c {
                    //     clip-path: polygon(0 30px,30px 0,100% 0,100% calc(100% - 30px),calc(100% - 30px) 100%,0 100%);
                    // }
            
                    &-number {
                        color: white;
                        font-size: 4.5rem;
                        font-family: "tt-autonomous", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
                        font-weight: 200;
                        line-height: 0.8;
                        grid-area: number;
                        text-decoration: underline;
                        text-decoration-thickness: 0.6px;
                        text-decoration-color: white;
                        padding: 0.25vh;
                        height: fit-content;
                        margin: 0.5vh 0.25vw;
                    }
            
                    &-tags {
                        color: white;
                        grid-area: tags;
                        width: 85%;
                        height: fit-content;
                        font-size: 2rem;
                        margin-top: 1vh;
                        margin-left: 1.5em;
                        margin-right: 1.5em;
                        padding: 0;
                        text-wrap: wrap;
                    }
                    
                    &-category {
                        color: white;
                        grid-area: category;
                        font-size: 1.325rem;
                        margin: 1vh 0.5vw;
                        height: 100%;
                        width: 90%;
                        text-wrap: wrap;
                    }
            
                    & > span {
                        display: inline-bock;
                        position: relative;
                        overflow: hidden;
                    }
                }
            }

            .card:hover .card__img {
                border-radius: 20px;
            }

            &-brief {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                position: relative;
                height: auto;
                font-family: $font-family;
                margin: 0;
                padding: 0;
                z-index: 2;
                color: white;
                background-color: #26363b;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                width: 100%;

                h1 {
                    margin-top: 0;
                    margin-bottom: 1.5vh;
                    margin-left: 1vw;
                    padding: 0;
                    font-size: 2.75rem;
                    font-family: $font-family;
                    font-weight: 100;
                    text-align: center;
                    text-decoration: underline;
                    text-decoration-color: white;
                }
                
                &-topics {
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    margin: 0 2vw;
                    margin-left: 1vw;
                    font-size: 1.2rem;
                    gap: 1vw;
                    color: black;

                    p {
                        border-radius: 9999px;
                        background-color: white;
                        padding: 1vh;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        text-align: center;
                    }
                }

                &-tech {
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    height: auto;
                    color:white;
                    font-size: 3rem;
                    margin-top: 1.5vh;
                    margin-bottom: 1.5vh;
                    margin-left: 0.5vw;

                    &-python, &-css, &-html, &-js, &-jupyter, &-react, &-pandas, &-numpy, &-scikit, &-googlecloud, &-openai, &-googlegemini, &-flask {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 0 0.4vw;
                    }

                    &-python > span, &-css > span, &-html > span, &-js > span, &-jupyter > span, &-react > span, &-pandas > span, &-numpy > span, &-scikit > span, &-googlecloud > span, &-openai > span, &-googlegemini > span, &-flask > span {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        width: 30px;
                        overflow: wrap;
                        font-size: small;
                        font-weight: 600;
                        background-color: inherit;
                        bottom: 100%;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.2s ease;
                    }

                    &-python > span::before, &-css > span::before, &-html > span::before, &-js > span::before, &-jupyter > span::before, &-react > span::before, &-pandas > span::before, &-numpy > span::before, &-scikit > span::before, &-googlecloud > span::before, &-openai > span::before, &-googlegemini > span::before, &-flask > span::before {
                        position: absolute;
                        content: "";
                        width: 30px;
                        height: 10px;
                        background-color: inherit;
                        transform: rotate(45deg);
                        top: calc(100% - 6px);
                    }

                    &-css:hover, &-html:hover, &-js:hover, &-jupyter:hover, &-react:hover, &-python:hover, &-pandas:hover, &-numpy:hover, &-scikit:hover, &-googlecloud:hover, &-openai:hover, &-googlegemini:hover, &-flask:hover {
                        color:rgba(11, 247, 255, 0.855);
                        span {
                            visibility: visible;
                            opacity: 1;
                            bottom: calc(100% + 6px);
                            color: white;
                        }
                    }
                }

                & > span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding-left: 1vw;
                    align-items: center;
                    font-size: 1.75rem;
                    width: 100%;
                    margin: 0;
                    
                    a {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                    }

                    p {
                        margin: 0.1vh 0.5vw;
                    }
                }

                .open-icon {
                    color: white;
                    height: fit-content;
                }
            }
            transition: transform 0.05s ease-in-out;
            &:hover {
                transform: scale(1.025);
            }
        }
    }

    & > h1 {
        position: relative;
        text-align: left;
        font-size: 4rem;
        color: white;
        width: 80vw;
        margin: 0;
    }

    & > hr {
        position: relative;
        width: 80vw;
        margin: 0;
        border: 0.1rem solid white;
    }

    & > p {
        position: relative;
        font-size: 2.5rem;
    }

    & > img {
        position: relative;
        top: 4vh;
        width: 2vw;
        height: 2vh;
        animation: bounce 3s infinite;
    }
}