$font-family: 'open-sans', sans-serif;
.navbar {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    position: fixed;
    top: 1vh;
    width: 100%;
    z-index: 9999;
}

.home-link  {
    margin: 2vw;
    text-decoration: none;
    font-family: $font-family;
    font-weight: bold;
}

.home-link > img {
    width: clamp(10px, 5vw, 50px);
    height: clamp(10px, 5vw, 50px);
}

.links {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5rem;
    padding: 10px;
    margin-right: 1.5vw;
}

.link {
    display: inline-block;
    position: relative;
    padding: 10px;
    text-decoration: none;
    color: white;
}

.link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: middle;
    transition: transform 0.25s ease-out;
}

.link:hover::after {
    transform: scaleX(1);
    transform-origin: middle;
}